/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  overflow-x: hidden;
}
.neuro {
  background-color: lightgray;
}
.anesthesia {
  /*background-color: rgb(125 211 252)*/

  background-color: #63a4ff;
  background-image: linear-gradient(315deg, #83eaf1 0%, #63a4ff 74%);
}

.fractures {
  background-color: rgb(253 164 175);
}

.modalTest {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}
